import React, { useEffect, useState } from 'react';
import endpoints from '../constants/endpoints';

function Social() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(endpoints.social, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => setData(res))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="social">
      {data ? (
        data.social.map((social) => (
          <button
            key={social.href} // Using href as the key assuming it's unique
            className="buttonStyle" // Apply class for styling
            style={{
              backgroundImage: `url(${social.background})`, // Apply the background image
            }}
            onClick={() => window.open(social.href, '_blank')}
            type="button" // Explicitly specifying the button type
          >
            {/* If you want to add any content inside the button, do it here */}
          </button>
        ))
      ) : null}
    </div>
  );
}

export default Social;
