import React from "react";
import "./BackgroundAnimation.scss";

const BackgroundAnimation = () => {
  // Generate an array of 100 elements for circles
  const circles = Array.from({ length: 100 });

  return (
    <div className="effect-container">
      {circles.map((_, index) => (
        <div key={index} className="circle-container">
          <div className="circle"></div>
        </div>
      ))}
    </div>
  );
};

export default BackgroundAnimation;
